<template>
  <div>
    <v-card elevation="8" max-width="300" height="408">
      <v-card-text>
        <v-img height="180" contain src="@/assets/tandt.png"></v-img>
      </v-card-text>
      <v-card-title>Avvisi</v-card-title>

      <v-card-text>
        <v-row><v-col></v-col></v-row>
        <v-row class="mx-0">
          Invia una comunicazione
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col class="text-right">
          <v-btn
            color="primary"
            class="ma-2"
            @click="$router.push({ path: '/texting' })"
          >
            Dettagli
          </v-btn></v-col
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "WidgetMsg",
  data() {
    return {

    };
  },

};
</script>
