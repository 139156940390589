<template>
  <v-main app>
    <v-container>
      <!-- Common widgets -->
      <v-row class="mt-2 ml-2">
        <widget-data class="mt-3 ml-3"></widget-data>

        <widget-rooms class="mt-3 ml-3"></widget-rooms>

        <widget-assets class="mt-3 ml-3"></widget-assets>

        <widget-purchases class="mt-3 ml-3"></widget-purchases>

        <widget-containers class="mt-3 ml-3"></widget-containers>

        <widget-housings class="mt-3 ml-3"></widget-housings>

        <!-- Administrative, Operator and Auditor widgets -->
        <div
          v-if="
            userRole === 'admin' ||
            userRole === 'operator' ||
            userRole === 'auditor'
          "
        >
          <widget-dbsync class="mt-3 ml-3"></widget-dbsync>
        </div>
        <div
          v-if="
            userRole === 'admin' ||
            userRole === 'operator' ||
            userRole === 'auditor'
          "
        >
          <widget-provider class="mt-3 ml-3"></widget-provider>
        </div>

        <!-- Only for Admin -->
        <!-- Administrative widgets -->
        <div v-if="userRole === 'admin'">
          <widget-msg class="mt-3 ml-3"></widget-msg>
        </div>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import WidgetRooms from "@/components/widgets/WidgetRooms.vue";
import WidgetAssets from "@/components/widgets/WidgetAssets.vue";
import WidgetData from "@/components/widgets/WidgetData.vue";
import WidgetPurchases from "@/components/widgets/WidgetPurchases.vue";
import WidgetDbsync from "@/components/widgets/WidgetDbsync.vue";
import WidgetMsg from "@/components/widgets/WidgetMsg.vue";
import WidgetProvider from "@/components/widgets/WidgetProvider.vue";
import WidgetContainers from "@/components/widgets/WidgetContainers.vue";
import WidgetHousings from "@/components/widgets/WidgetHousings.vue";

import { mapState } from "vuex";

export default {
  name: "Home",
  components: {
    WidgetRooms,
    WidgetAssets,
    WidgetData,
    WidgetPurchases,
    WidgetDbsync,
    WidgetMsg,
    WidgetProvider,
    WidgetContainers,
    WidgetHousings,
  },

  computed: {
    ...mapState(["userInitials", "userRole"]),
  },
};
</script>
