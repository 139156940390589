<template>
  <div>
    <v-card elevation="8" max-width="300" height="408" >
      <v-card-text>
        <v-img height="178" contain src="@/assets/mycontainers.png"></v-img>
      </v-card-text>
      <v-card-title>I miei containers</v-card-title>
      <v-card-text>
       
        <v-row align=center class="mx-0">
          Docker containers
          <span>
            <v-chip v-if="error" class="ma-2">?</v-chip>
            <v-chip v-else-if="!loading" class="ma-2">{{ stacksLength }}</v-chip>
            <v-chip v-else class="ma-2"
              ><v-progress-circular
                indeterminate
                size="8"
                width="2"
              ></v-progress-circular
            ></v-chip>
          </span>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col class="text-right">
          <v-btn
            color="primary"
            class="ma-2"
            @click="$router.push({ path: '/mycontainers' })"
          >
            Dettagli
          </v-btn></v-col
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "WidgetContainers",
  data() {
    return {
      stacks: [],
      loading: false,
      error: false,
      stacksLength: '?',
    };
  },
  mounted: function () {
    this.loadStacks();
  },
  methods: {
    loadStacks: function () {
      this.loading = true;
      this.error = false;
      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/stacks/" + this.$store.state.userMatricola + '/list', {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          this.stacks = response.data;
          this.stacksLength = this.stacks.length;
          this.loading = false;
        })
        .catch(() => {
          this.error = true;
          this.loading = false;
        });
    },
  },
};
</script>
