<template>
  <div>
    <v-card elevation="8" max-width="300">
      <v-card-text>
        <v-img height="180" contain src="@/assets/mydata.png"></v-img>
      </v-card-text>
      <v-card-title>I miei dati</v-card-title>

      <v-card-text>
        <v-row><v-col></v-col></v-row>
        <v-row class="mx-0">
          Dati personali
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-col class="text-right">
          <v-btn
            color="primary"
            class="ma-2"
            @click="$router.push({ path: '/mydata' })"
          >
            Dettagli
          </v-btn></v-col
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "WidgetData",
  data() {
    return {
      loading: false,
      error: false,
    };
  },
  methods: {
  },
};
</script>
