var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',{attrs:{"app":""}},[_c('v-container',[_c('v-row',{staticClass:"mt-2 ml-2"},[_c('widget-data',{staticClass:"mt-3 ml-3"}),_c('widget-rooms',{staticClass:"mt-3 ml-3"}),_c('widget-assets',{staticClass:"mt-3 ml-3"}),_c('widget-purchases',{staticClass:"mt-3 ml-3"}),_c('widget-containers',{staticClass:"mt-3 ml-3"}),_c('widget-housings',{staticClass:"mt-3 ml-3"}),(
          _vm.userRole === 'admin' ||
          _vm.userRole === 'operator' ||
          _vm.userRole === 'auditor'
        )?_c('div',[_c('widget-dbsync',{staticClass:"mt-3 ml-3"})],1):_vm._e(),(
          _vm.userRole === 'admin' ||
          _vm.userRole === 'operator' ||
          _vm.userRole === 'auditor'
        )?_c('div',[_c('widget-provider',{staticClass:"mt-3 ml-3"})],1):_vm._e(),(_vm.userRole === 'admin')?_c('div',[_c('widget-msg',{staticClass:"mt-3 ml-3"})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }